const ENDPOINTS = {
  pages: 'pages',
  page: 'pages',
  footer: 'footer',
  header: 'header',
  news: 'news',
  external_projects: 'external_projects',
  useful_tools: 'useful_tools',
  best_practices: 'best_practices',
  sub_investments: 'sub_investments',
  internal_projects: 'internal_projects',
  // revision: 'revision',
}

const API_URLS = {
  BUILD_GET_NEWS_URL: (
    baseUrl = '',
    searchTerm = null,
    newsCategory = null,
    orderBy = 'start_date',
    tags = null,
    WPOrder = null,
  ) =>
    `${baseUrl}news?${searchTerm ? `&search=${searchTerm}` : ''}${
      newsCategory ? `&news_category=${newsCategory}` : ''
    }${orderBy ? `&orderby=${orderBy}` : ''}${tags ? `&tags=${tags}` : ''}${
      WPOrder ? `&order=${WPOrder}` : ''
    }`,
  GET_NEWS_CATEGORIES: (baseUrl = '') => `${baseUrl}news_category`,
  BUILD_GET_EXTERNAL_PROJECTS_URL: (
    baseUrl = '',
    searchTerm = null,
    orderBy = 'start_date',
    tags = null,
    WPOrder = null,
  ) =>
    `${baseUrl}external_projects?${searchTerm ? `&search=${searchTerm}` : ''}${
      tags ? `&tags=${tags}` : ''
    }${orderBy ? `&orderby=${orderBy}` : ''}${
      WPOrder ? `&order=${WPOrder}` : ''
    }`,
  BUILD_GET_USEFUL_TOOLS_URL: (
    baseUrl = '',
    searchTerm = null,
    usefultoolsCategory = null,
    orderBy = 'start_date',
    tags = null,
    WPOrder = null,
  ) =>
    `${baseUrl}useful_tools?${searchTerm ? `&search=${searchTerm}` : ''}${
      usefultoolsCategory ? `$useful_tools_category=${usefultoolsCategory}` : ''
    }${orderBy ? `&orderby=${orderBy}` : ''}${tags ? `&tags=${tags}` : ''}${
      WPOrder ? `&order=${WPOrder}` : ''
    }`,
  GET_USEFUL_TOOL_CATEGORIES: (baseUrl = '') =>
    `${baseUrl}useful_tools_category`,
  BUILD_GET_BEST_PRACTICES_URL: (
    baseUrl = '',
    searchTerm = null,
    orderBy = 'start_date',
    tags = null,
    WPOrder = null,
  ) =>
    `${baseUrl}best_practices?${searchTerm ? `&search=${searchTerm}` : ''}${
      tags ? `&tags=${tags}` : ''
    }${orderBy ? `&orderby=${orderBy}` : ''}${
      WPOrder ? `&order=${WPOrder}` : ''
    }`,
  BUILD_GET_SUB_INVESTMENTS_URL: (
    baseUrl = '',
    searchTerm = null,
    orderBy = 'start_date',
    tags = null,
    WPOrder = null,
  ) =>
    `${baseUrl}sub_investments?${searchTerm ? `&search=${searchTerm}` : ''}${
      tags ? `&tags=${tags}` : ''
    }${orderBy ? `&orderby=${orderBy}` : ''}${
      WPOrder ? `&order=${WPOrder}` : ''
    }`,
  BUILD_GET_INTERNAL_PROJECTS_URL: (
    baseUrl = '',
    searchTerm = null,
    orderBy = 'start_date',
    tags = null,
    WPOrder = null,
  ) =>
    `${baseUrl}internal_projects?${searchTerm ? `&search=${searchTerm}` : ''}${
      tags ? `&tags=${tags}` : ''
    }${orderBy ? `&orderby=${orderBy}` : ''}${
      WPOrder ? `&order=${WPOrder}` : ''
    }`,
  GET_TAGS: (baseUrl = '') => `${baseUrl}tags`,
  BUILD_GENERIC_SEARCH_URL: (
    searchTerm = null,
    type = null,
    WPOrder = null,
    baseUrl = '',
  ) =>
    `${baseUrl}search?${searchTerm ? `s=${searchTerm}` : ''}${
      type ? `&type=${type}` : ''
    }${WPOrder ? `&order=${WPOrder}` : ''}`,
}

const CONTENT_TYPES = {
  NEWS: 'news',
  EXTERNAL_PROJECTS: 'external_projects',
  USEFUL_TOOLS: 'useful_tools',
  BEST_PRACTICES: 'best_practices',
  SUB_INVESTMENTS: 'sub_investments',
  INTERNAL_PROJECTS: 'internal_projects',
}

const PARENT_PAGE_BY_CONTENT_TYPE = {
  [CONTENT_TYPES.NEWS]: 'news',
  [CONTENT_TYPES.EXTERNAL_PROJECTS]: 'esempi-virtuosi',
  [CONTENT_TYPES.USEFUL_TOOLS]: 'strumenti-utili',
  [CONTENT_TYPES.BEST_PRACTICES]: 'buone-pratiche',
  [CONTENT_TYPES.SUB_INVESTMENTS]: 'sub-investimenti',
  [CONTENT_TYPES.INTERNAL_PROJECTS]: 'progetti',
}

export { API_URLS, CONTENT_TYPES, ENDPOINTS, PARENT_PAGE_BY_CONTENT_TYPE }
